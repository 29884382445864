import React, { useState, useRef } from 'react'
import { string, arrayOf } from 'prop-types'
import Container from '@/component/Primitive/Container'
import Icon from '@/component/Primitive/Icon'
import Type from '@/component/Primitive/Type'

import Filter from 'bad-words'
import useSound from 'use-sound'

import correctSound from '@/asset/audio/correct.mp3'
import incorrectSound from '@/asset/audio/incorrect.mp3'

import IssueItem from './IssueItem'
import styles from '../SpotTheIssues.module.scss'

import WatchStep from './WatchStep/WatchStep'
import ScoreStep from './ScoreStep/ScoreStep'

const steps = ['education', 'watch', 'score']

// Function to convert timestamps to seconds
const convertTimestampToSeconds = (timestamp) => {
  const [minutes, seconds] = timestamp.split(':')
  return parseInt(minutes) * 60 + parseFloat(seconds)
}

const GameWidget = ({
  id,
  title,
  videoId,
  issues,
  timestamps,
  behindTheClassification,
  overallRating,
  videoProvider,
  onClose,
  onSubmit
}) => {
  const [score, setScore] = useState({
    totalScore: 0,
    scoreList: []
  })
  const [checkAnswer, setCheckAnswer] = useState(null)
  const [name, setName] = useState('')
  const [age, setAge] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const playedRef = useRef(0)

  const filter = new Filter()

  const [currentStep, setCurrentStep] = useState(0)

  const [playCorrect] = useSound(correctSound)
  const [playIncorrect] = useSound(incorrectSound)

  const issuesMap = issues.map((item) => ({
    type: item.type,
    emoji: item.emoji,
    description: item.description
  }))

  const issuesLookup = issues.reduce((lookup, item) => {
    lookup[item.type] = item.emoji
    return lookup
  }, {})
  const timestampsMap = timestamps.map((item) => {
    const issueEmoji = issuesLookup[item.issue]
    return {
      issue: item.issue,
      id: item.id,
      item: item.timestamp,
      emoji: issueEmoji
    }
  })
  const playAgainHandle = () => {
    // go to watch step
    setCurrentStep(1)

    // reset score
    setScore({
      totalScore: 0,
      scoreList: []
    })
  }
  const handleStep = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (filter.isProfane(name)) {
      setErrorMessage('Please enter a valid name')
      return
    }

    const user = { name, age }
    onSubmit(user)
    handleStep()
  }

  const handleClick = (type, currentTime, id) => {
    const timeRange = 2
    let matchedTimestamp = false

    for (const timestamp of timestamps) {
      if (timestamp.issue === type) {
        const timestampInSeconds = timestamp.timestamp.includes(':')
          ? convertTimestampToSeconds(timestamp.timestamp)
          : parseFloat(timestamp.timestamp)

        const timeDifference = Math.abs(currentTime - timestampInSeconds)

        if (timeDifference <= timeRange) {
          const maxScore = 100
          // Calculate the score based on the proximity to the timestamp
          const calculatedScore =
            maxScore - Math.round((timeDifference / timeRange) * maxScore)

          // Ensure the calculated score is at least 0
          const finalScore = Math.max(calculatedScore, 0)

          const updatedScoreList = [
            ...score.scoreList,
            {
              id,
              type,
              score: finalScore
            }
          ]

          setScore((prevScore) => ({
            totalScore: prevScore.totalScore + finalScore,
            scoreList: updatedScoreList
          }))

          setCheckAnswer(true)

          playCorrect()

          matchedTimestamp = true

          break
        }
      }
    }

    if (!matchedTimestamp) {
      const updatedScoreList = [
        ...score.scoreList,
        {
          id,
          type,
          score: 0
        }
      ]
      setScore((prevScore) => ({
        totalScore: prevScore.totalScore,
        scoreList: updatedScoreList
      }))
      playIncorrect()
      setCheckAnswer(false)
    }

    setTimeout(() => {
      setCheckAnswer(null)
    }, 1000)
  }

  const handleRating = async (rating) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const leaderboardObject = {
      id: user.id,
      name: user.name,
      score: score.totalScore,
      scoreList: score.scoreList
    }

    try {
      const response = await fetch(`/api/submit-spot-the-issue/${id}`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(leaderboardObject)
      })

      if (response.ok) {
        handleStep()
      } else {
        console.error('Error submitting data:', response.statusText)
      }
    } catch (error) {
      console.error('Error submitting data:', error.message)
    }
  }

  // Get the total score for the game + 100 for correctly rating the trailer
  const gameScore = timestamps.length * 100 + 100

  return (
    <div className={styles.GameWidget}>
      <button onClick={onClose} className={styles.CloseButton}>
        <Icon type="close" className={styles.CloseIcon} a11yText="Close" />
      </button>
      <div className={styles.GameWidgetContainer}>
        <Container gutter center className={styles.ContentContainer}>
          {steps[currentStep] === 'education' && (
            <>
              <div className={styles.GameWidgetIntro}>
                <Type
                  size="display2"
                  color="white"
                  className={styles.GameWidgetTitle}
                >
                  Click on the emojis below to learn what to look out for:{' '}
                </Type>
              </div>
              <div className={styles.IssueList}>
                {issues &&
                  issues.length > 0 &&
                  issuesMap.map((issue, index) => (
                    <IssueItem key={index} item={issue} />
                  ))}
              </div>
              <form className={styles.AgeForm} onSubmit={handleSubmit}>
                {errorMessage && (
                  <Type size="base" color="red" className={styles.InputError}>
                    {errorMessage}
                  </Type>
                )}
                <input
                  className={styles.Input}
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  placeholder="Name"
                />
                <input
                  className={styles.Input}
                  type="number"
                  onChange={(e) => setAge(e.target.value)}
                  required
                  placeholder="Age"
                />
                <button className={styles.Button} type="submit">
                  Play!
                </button>
              </form>
            </>
          )}
          {steps[currentStep] === 'watch' && (
            <WatchStep
              id={id}
              videoId={videoId}
              playedRef={playedRef}
              handleClick={handleClick}
              timestamps={timestampsMap}
              checkAnswer={checkAnswer}
              overallRating={overallRating}
              handleRating={handleRating}
              score={score}
              issueList={timestampsMap}
              videoProvider={videoProvider}
            />
          )}
          {steps[currentStep] === 'score' && (
            <ScoreStep
              title={title}
              score={score}
              issueList={timestampsMap}
              id={id}
              overallRating={overallRating}
              gameScore={gameScore}
              behindTheClassification={behindTheClassification}
              playAgainHandle={playAgainHandle}
            />
          )}
        </Container>
        <Icon
          className={styles.FamilyIcon}
          type="family--green"
          width={200}
          a11yText="Family Icon"
        />
        <Icon className={styles.FishOne} type="fish--green" width={100} />
        <Icon className={styles.FishTwo} type="fish--orange" width={75} />
      </div>
    </div>
  )
}

GameWidget.propTypes = {
  id: string,
  title: string,
  videoId: string,
  issues: arrayOf(string),
  timestamps: arrayOf(string),
  behindTheClassification: string,
  overallRating: string,
  videoProvider: string,
  onClose: () => {},
  onSubmit: () => {}
}

export default GameWidget
