import React, { useState, useCallback, useRef, useEffect } from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'

import ReactPlayer from 'react-player'
import Countdown from 'react-countdown'

import Type from '@/component/Primitive/Type'

import CountDownRenderer from './CountdownRenderer'
import IssueActionButton from './IssueActionButton'
import LoadingImage from '@/asset/img/loading.gif'

import classNames from 'classnames'

import { number, func, string, arrayOf, shape, bool } from 'prop-types'

import styles from './WatchStep.module.scss'

const WatchStep = ({
  videoId,
  playedRef,
  handleClick,
  timestamps,
  checkAnswer,
  overallRating,
  handleRating,
  score,
  issueList,
  videoProvider
}) => {
  const startTime = React.useRef(Date.now())
  const [videoDuration, setVideoDuration] = useState(2000)
  const correctAnswer = checkAnswer === true
  const incorrectAnswer = checkAnswer === false
  const [timeRemaining, setTimeRemaining] = useState(true)

  const handleProgress = (progress) => {
    playedRef.current = progress.playedSeconds
  }

  const groupedTimestamps = timestamps.reduce((grouped, timestamp) => {
    if (!grouped[timestamp.issue]) {
      grouped[timestamp.issue] = []
    }
    grouped[timestamp.issue].push(timestamp)
    return grouped
  }, {})

  const refAnimationConfetti = useRef(null)

  const getConfetti = useCallback((confetti) => {
    refAnimationConfetti.current = confetti
  }, [])

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationConfetti.current &&
      refAnimationConfetti.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio)
      })
  }, [])

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55
    })

    makeShot(0.2, {
      spread: 60
    })

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    })

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    })

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45
    })
  }, [makeShot])

  useEffect(() => {
    if (correctAnswer) {
      fire()
    }
  }, [correctAnswer, fire])

  useEffect(() => {
    if (!timeRemaining) {
      handleRating()
    }
  }, [timeRemaining, handleRating])

  const videoURL =
    videoProvider === 'YouTube'
      ? `https://www.youtube.com/watch?v=${videoId}`
      : `https://vimeo.com/${videoId}`
  if (!timeRemaining) {
    return (
      <div className={styles.Loading}>
        <img alt="loading result" src={LoadingImage} />
      </div>
    )
  }
  return (
    <div
      className={classNames(
        styles.WatchStep,
        correctAnswer && styles['correct-background'],
        incorrectAnswer && styles['incorrect-background']
      )}
    >
      <>
        <div
          className={classNames(
            styles.ReactIcon,
            correctAnswer && styles.ShowIcon
          )}
        >
          <span className={styles.ReactIconText}>😃</span>
          <ReactCanvasConfetti
            className={styles.ReactIconConfetti}
            refConfetti={getConfetti}
          />
        </div>
        <div
          className={classNames(
            styles.ReactIcon,
            incorrectAnswer && styles.ShowIcon
          )}
        >
          <span className={styles.ReactIconText}>😔</span>
        </div>

        <div className={styles.WatchStepTopContent}>
          {correctAnswer && (
            <Type
              size="display1"
              color="white"
              className={styles.WatchStepHeading}
            >
              😃 GREAT JOB!
            </Type>
          )}
          {incorrectAnswer && (
            <Type
              size="display1"
              color="white"
              className={styles.WatchStepHeading}
            >
              😔 KEEP TRYING
            </Type>
          )}

          <div className={styles.CountDownWrap}>
            <Type color="white" size="small" className={styles.Heading}>
              Time Remaining
            </Type>
            <Countdown
              date={startTime.current + videoDuration}
              renderer={CountDownRenderer}
            />
          </div>
        </div>
        <div className={styles.WatchStepTrailer}>
          <ReactPlayer
            controls={false}
            onProgress={handleProgress}
            playing
            className={styles.WatchStepTrailerPlayer}
            url={videoURL}
            width="auto"
            height="auto"
            onDuration={(duration) => setVideoDuration(duration * 1000)}
            style={{ pointerEvents: 'none' }}
            onEnded={() => setTimeRemaining(false)}
          />
        </div>
        <div className={styles.WatchStepContent}>
          <div className={styles.IssueActionButtons}>
            {Object.keys(groupedTimestamps).map((issue) => (
              <IssueActionButton
                key={issue}
                item={groupedTimestamps[issue][0]}
                playedRef={playedRef}
                handleClick={handleClick}
              />
            ))}
          </div>
        </div>
      </>
    </div>
  )
}

WatchStep.propTypes = {
  videoId: string,
  playedRef: shape({ current: number }),
  handleClick: func,
  timestamps: arrayOf(
    shape({
      id: string,
      label: string,
      emoji: string,
      type: string,
      start: number,
      end: number
    })
  ),
  checkAnswer: bool,
  overallRating: number,
  handleRating: func,
  score: string,
  issueList: string,
  videoProvider: string
}

export default WatchStep
